import ReportBuilderProfessional from "./ReportBuilderProfessional";
import ReportBuilderStudent from "./ReportBuilderStudent";
import ReportBuilderText from "./ReportBuilderText";
import ReportBuilderException from "./ReportBuilderException";

export default class ReportBuilder {

    ReportType = "";
    SupportedTypes = ["Professional", "Student", "Text"];
    ReportBuilder = {};

    constructor(reportType) {
        if (this.SupportedTypes.filter(i => i === reportType).length === 1) {
            this.ReportType = reportType;
            switch (reportType) {
                case "Professional":
                    {
                        this.ReportBuilder = new ReportBuilderProfessional();
                        break;
                    }
                case "Student":
                    {
                        this.ReportBuilder = new ReportBuilderStudent();
                        break;
                    }
                case "Text":
                    {
                        this.ReportBuilder = new ReportBuilderText();
                        break;
                    }
            }
        } else {
            throw new ReportBuilderException(`The argument ${reportType} was not in the list of valid report types.`);
        }
    }

    BuildReport(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points) {

        if (this.ReportType === "") {
            throw new ReportBuilderException(`The initial report type is not in the list of valid report types.`);
        }

        return this.ReportBuilder.BuildReport(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points);
    }

    // returns a blob
    BuildDocx(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points) {
        if (this.ReportType === "") {
            throw new ReportBuilderException(`The initial report type is not in the list of valid report types.`);
        }

        return this.ReportBuilder.BuildDocx(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points);
    }

    ReportName() {
        if (this.ReportType === "") {
            throw new ReportBuilderException(`The initial report type is not in the list of valid report types.`);
        }

        return this.ReportBuilder.ReportName;
    }
}