/* eslint no-dupe-class-members: 0 */
import * as docx from "docx";
import * as disp from "@/utility/display.js";
import {
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    AlignmentType,
    // LevelFormat,
    convertInchesToTwip,
    LevelFormat
    //   UnderlineType,
    //   convertInchesToTwip
} from "docx";

export default class DocxBuilder {

    childList = null;
    document = null;

    leftMarginInches = 0.5;
    rightMarginInches = 0.5;
    topMarginInches = 0.5;
    bottomMarginInches = 0.5;

    constructor() {
        this.#SetupDocument();
    }

    #MarginInchesToString(margin) {
        return `${margin}in`
    }

    #FigureRightTabPosition() {
        // TabStopPosition.Max assumes 1 inch margins
        return TabStopPosition.MAX + convertInchesToTwip(1 - this.leftMarginInches + (1 - this.rightMarginInches));
    }

    #SetupDocument() {
        if (this.document == null) {
            this.document = new docx.Document({
                styles: {
                    default: {
                        heading1: {
                            basedOn: "Normal",
                            next: "Normal",
                            run: {
                                size: 12 * 2,
                                color: "#000000",
                                bold: true,
                                font: "Source Serif Pro"
                            },
                            paragraph: {
                                spacing: {
                                    after: 120,
                                    before: 240
                                }
                            }
                        },
                        heading2: {
                            basedOn: "Normal",
                            next: "Normal",
                            run: {
                                size: 10 * 2,
                                color: "#000000",
                                font: "Source Serif Pro"
                            },
                            paragraph: {
                                spacing: {
                                    before: 120,
                                }
                            }
                        },
                        heading3: {
                            basedOn: "Normal",
                            next: "Normal",
                            run: {
                                size: 10 * 2,
                                color: "#000000",
                                font: "Source Serif Pro"
                            },
                        },
                        title: {
                            basedOn: "Normal",
                            next: "Normal",
                            run: {
                                size: 14 * 2,
                                bold: true,
                                color: "#000000",
                                font: "Source Serif Pro"
                            }
                        },
                        normal: {
                            run: {
                                size: 10 * 2,
                                bold: false,
                                color: "#000000",
                                font: "Source Serif Pro"
                            }
                        },
                    }
                },
                numbering: {
                    config: [
                        {
                            reference: "default-bullet",
                            levels: [
                                {
                                    level: 0,
                                    format: LevelFormat.BULLET,
                                    size: 48,
                                    text: "\u2022",
                                    alignment: AlignmentType.LEFT,
                                    style: {
                                        paragraph: {
                                            indent: { left: convertInchesToTwip(0.125), hanging: convertInchesToTwip(0.1) },
                                        },
                                    },
                                }
                            ]
                        }
                    ]
                },
                // paragraphStyles: [
                //     {
                //         id: "normalPara",
                //         name: "Normal Para",
                //         basedOn: "Normal",
                //         next: "Normal",
                //         quickFormat: true,
                //         run: {
                //             font: "Calibri",
                //             size: 26,
                //             bold: true,
                //             color: "red"
                //         }
                //     }
                // ],
                // numbering: {
                //     config: [
                //         {
                //             reference: "my-crazy-numbering",
                //             levels: [
                //                 {
                //                     level: 0,
                //                     format: LevelFormat.LOWER_LETTER,
                //                     text: "%1)",
                //                     alignment: AlignmentType.LEFT
                //                 }
                //             ]
                //         }
                //     ]
                // },
                sections: []
            });
        }

        if (this.titleHeading == null) {
            this.titleHeading = HeadingLevel.TITLE;
        }

        if (this.childList == null) {
            this.childList = [];
        }

        if (this.heading1 == null) {
            this.heading1 = HeadingLevel.HEADING_1;
        }

        if (this.heading2 == null) {
            this.heading2 = HeadingLevel.HEADING_2;
        }

        if (this.heading3 == null) {
            this.heading3 = HeadingLevel.HEADING_3;
        }

        if (this.normal == null) {
            this.normal = "normal";
        }

        return this;
    }

    AddTitle(text) {
        if (disp.IsNullorWhitespace(text)) {
            return this;
        }

        this.childList.push(
            new Paragraph({
                children: [new TextRun(text)],
                heading: this.titleHeading
            })
        );
        return this;
    }

    AddHeading1(text, horizontalRule = false) {
        if (disp.IsNullorWhitespace(text)) {
            return this;
        }

        let border = {};
        if (horizontalRule) {
            border = {
                bottom: {
                    color: "#000000",
                    space: 0,
                    style: "single",
                    size: 10
                }
            }
        }

        this.childList.push(
            new Paragraph({
                children: [new TextRun(text)],
                heading: this.heading1,
                border: border
            })
        );
        return this;
    }

    AddSplitHeading(textLeft, textRight) {
        if (disp.IsNullorWhitespace(textLeft) && disp.IsNullorWhitespace(textRight)) {
            return this;
        }

        this.childList.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: textLeft,
                        bold: true,
                        italics: false,
                    }),
                    new TextRun({
                        text: `\t${textRight}`
                    })
                ],
                heading: this.heading2,
                tabStops: [
                    {
                        type: TabStopType.RIGHT,
                        position: this.#FigureRightTabPosition()
                    }
                ]
            })
        );

        return this;
    }

    AddSplitSubHeading(textLeft, textRight) {
        if (disp.IsNullorWhitespace(textLeft) && disp.IsNullorWhitespace(textRight)) {
            return this;
        }

        this.childList.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: textLeft,
                        bold: true,
                        italics: true,
                    }),
                    new TextRun({
                        text: `\t${textRight}`,
                        italics: true,
                    })
                ],
                heading: this.heading3,
                tabStops: [
                    {
                        type: TabStopType.RIGHT,
                        position: this.#FigureRightTabPosition()
                    }
                ]
            })
        );

        return this;
    }

    AddFormattedBulletList(labels, values) {
        if (labels.length === null || labels.length !== values.length) {
            throw 'Label and value list lenghts did not match when building a bulleted list!';
        }

        if (labels.length === 0) {
            return this;
        }

        let i = 0;
        for (i = 0; i < labels.length; i++) {
            this.childList.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: labels[i],
                            bold: true,
                            italics: true,
                        }),
                        new TextRun({
                            text: values[i],
                        })
                    ],
                    heading: this.heading3,
                    numbering: {
                        reference: "default-bullet",
                        level: 0,
                    }
                })
            )
        };

        return this;
    }

    AddBulletList(lines) {
        if (lines.length === 0) {
            return this;
        }

        let i = 0;
        for (i = 0; i < lines.length; i++) {
            this.childList.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: lines[i],
                        }),
                    ],
                    heading: this.heading3,
                    numbering: {
                        reference: "default-bullet",
                        level: 0,
                    }
                })
            )
        };

        return this;
    }

    AddParagraph(text) {
        if (disp.IsNullorWhitespace(text)) {
            return this;
        }

        this.childList.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: text,
                    }),
                ],
                heading: this.heading3,
            })
        );
        return this;
    }

    BuildSection() {
        this.#SetupDocument();
        this.document.addSection({
            properties: {
                page: {
                    margin: {
                        top: this.#MarginInchesToString(this.topMarginInches),
                        right: this.#MarginInchesToString(this.rightMarginInches),
                        bottom: this.#MarginInchesToString(this.bottomMarginInches),
                        left: this.#MarginInchesToString(this.leftMarginInches)
                    },
                }
            },
            children: [...this.childList]
        });
        this.childList = null;
        return this;
    }



    async GetBlob() {
        return await docx.Packer.toBlob(this.document);
    }
}