import * as uuid from "../utility/guid";
import ReportBuilderException from "./ReportBuilderException";
import * as disp from "../utility/display";

export default class ReportBuilderBase {

    BuildWorkDatePairs(workDict) {
        return Object.values(workDict).filter(w => w != null && w.workGeneral != null).map(work => {
            let endDate = work.workGeneral.workingCurrently ? new Date() : work.workGeneral.endDate;
            return { startDate: work.workGeneral.startDate, endDate: endDate };
        })
    }

    CheckParam(value, name, methodName) {
        if (value === undefined) {
            throw new ReportBuilderException(`The parameter ${name} was unset or undefined. This parameter is required for the call to ${methodName}`);
        }
    }

    CleanFormatting(text) {
        return text.replace(/<bold>/gi, '').replace(/<\/bold>/gi, '').replace(/<italic>/gi, '').replace(/<\/italic>/gi, '');
    }

    // takes an array of objects that have a startDate and endDate parameter
    CalcYearsExperience(datePairs) {
        if (datePairs === undefined || datePairs === null || datePairs.length < 1) {
            return "";
        }

        let datePairArr = [];
        datePairArr = datePairs.map(pair => {
            let id = uuid.gen();
            return [{ date: pair.startDate, id: id }, { date: pair.endDate, id: id }];
        })
            .reduce((p, c) => [...p, ...c]);

        datePairArr.sort((a, b) => {
            return a.date - b.date;
        });

        let active = {};
        let totalTime = 0;
        let curStartDate;

        datePairArr.forEach(date => {
            if (Object.keys(active).length === 0) {
                curStartDate = date.date;
                active[date.id] = true;
            } else {
                if (active[date.id] === undefined) {
                    active[date.id] = true;
                } else {
                    delete active[date.id];
                    if (Object.keys(active).length === 0) {
                        totalTime += (date.date - curStartDate);
                    }
                }
            }
        })

        let years = Math.floor((totalTime / (1000 * 60 * 60 * 24 * 365)) + .17);    // round down, but within around 2 month round up
        if (years !== 1) {
            return `${years} years`;
        } else {
            return `${years} year`;
        }
    }

    BuildCompanyList(workDict) {
        if (workDict === undefined || workDict === null || Object.keys(workDict).length == 0) {
            return "";
        }

        let companyList = Object.values(workDict).filter(w => w !== null).map(w => w?.workGeneral?.companyName).filter(i => i?.length > 0);

        return this.BuildStringList(companyList);
    }

    BuildProfessionalSummary(adjectiveDisplay, careerInterestDisplay, roleDisplay, hardSkillsTopDisplay, softSkillsDisplay, workDict) {
        let intStr = "";
        let dataAdded = false;

        if (!disp.IsNullorWhitespace(adjectiveDisplay)) {
            intStr += disp.CapitalizeWords(adjectiveDisplay);
            dataAdded = true;
        }

        if (intStr.length == 0) {
            intStr += "Professional with";
        } else {
            intStr += " professional with";
        }

        let years = this.CalcYearsExperience(this.BuildWorkDatePairs(workDict));
        if (years != "0 years" && years != "") {
            intStr += ` ${years} of`;
            dataAdded = true;
        }

        if (!disp.IsNullorWhitespace(roleDisplay)) {
            intStr += ` experience as ${disp.GetLeadingArticle(roleDisplay)} ${disp.CapitalizeWords(roleDisplay)}. `
            dataAdded = true;
        } else {
            intStr += " experience. ";
        }

        if (!dataAdded) {
            intStr = "";
        }

        let companyList = this.BuildCompanyList(workDict);
        if (!disp.IsNullorWhitespace(companyList)) {
            intStr += "Experience includes working with ";
            intStr += companyList; // don't capitalize words here - chances aren't great that we get it right automatically.
            intStr += ". ";
        }

        if (!disp.IsNullorWhitespace(hardSkillsTopDisplay)) {
            intStr += `Expertise includes: ${disp.CapitalizeWords(hardSkillsTopDisplay)}. `;
        }

        if (!disp.IsNullorWhitespace(softSkillsDisplay)) {
            intStr += `Leadership skills include ${disp.CapitalizeWords(softSkillsDisplay)}. `;
        }

        if (!disp.IsNullorWhitespace(careerInterestDisplay)) {
            intStr += `Career interests include ${disp.CapitalizeWords(careerInterestDisplay)}. `;
        }

        return intStr;
    }

    BuildSkills1(hardSkills) {
        return this.BuildStringList(hardSkills.map(hs => hs.text));
    }

    BuildSkills2(softSkills, roles) {
        return this.BuildStringList([...softSkills.map(ss => ss.text), ...roles.map(r => r.text)]);
    }

    // returns array with two items, the header array and the skill array.
    BuildSkillArray(hardSkills, hardSkillsLabels, otherSkills) {
        let hdrArr = [];
        let sklArr = [];

        if (hardSkillsLabels != null && hardSkillsLabels.length >= 3) {
            let hs1 = hardSkills.filter(h => h.level === 1).map(h => h.text);
            let hs2 = hardSkills.filter(h => h.level === undefined || h.level === null || h.level === 2).map(h => h.text);
            let hs3 = hardSkills.filter(h => h.level === 3).map(h => h.text);

            if (hs1 != null && hs1.length > 0) {
                hdrArr.push(`${hardSkillsLabels[0].text}`);
                sklArr.push(this.BuildStringList(hs1));
            }
            if (hs2 != null && hs2.length > 0) {
                hdrArr.push(`${hardSkillsLabels[1].text}`);
                sklArr.push(this.BuildStringList(hs2));
            }
            if (hs3 != null && hs3.length > 0) {
                hdrArr.push(`${hardSkillsLabels[2].text}`);
                sklArr.push(this.BuildStringList(hs3));
            }
        }

        if (otherSkills != null && otherSkills.length > 0) {
            let os = otherSkills.map(o => o.text);
            hdrArr.push(`Other Skills`);
            sklArr.push(this.BuildStringList(os));
        }

        return [hdrArr, sklArr];
    }

    BuildStringList(strList) {
        if (!Array.isArray(strList)) {
            return "";
        }

        if (strList.length == 1) {
            return strList[0];
        } else if (strList.length == 2) {
            return strList[0] + " and " + strList[1];
        } else {
            let intString = "";
            let i = 0;
            for (i = 0; i < strList.length; i++) {
                intString += strList[i];

                if (i == strList.length - 2) {
                    intString += ", and ";
                } else if (i != strList.length - 1) {
                    intString += ", ";
                }
            }

            return intString;
        }
    }

    // Format the two lists into a single list with pdf report formatting.
    ReportFormatTwoLists(list1, list2) {
        let i = 0;
        let arr = []
        for (i = 0; i < list1.length; i++) {
            arr.push(`<bold><italic>${list1[i]}:</italic></bold> ${list2[i]}`);
        }
        return arr;
    }
}