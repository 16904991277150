/* eslint no-dupe-class-members: 0 */
function ReportRequestException(message) {
    this.message = message;
    this.name = 'ReportRequestException';
}

export default class ReportRequest {
    #curRelative;
    #relStack;
    #curId;

    constructor() {
        this.#relStack = [];
        this.#relStack.push([]);
        this.#curRelative = this.#relStack[0];
        this.#curId = [];
    }

    #NewRel(newRel) {
        this.#relStack.push(newRel);
        this.#curRelative = newRel;
    }

    #EndRel() {
        this.#relStack.pop();
        this.#curRelative = this.#relStack[this.#relStack.length - 1];
    }

    #CheckParam(value, name, methodName, extraInfo) {
        if (value === undefined) {
            throw new ReportRequestException(`The parameter ${name} was unset or undefined. This parameter is required for the call to ${methodName}. ${extraInfo}`);
        }
    }

    AddLine(id, value) {
        this.#CheckParam(id, "id", "AddLine");
        this.#CheckParam(value, "value", "AddLine", id);

        let line = { id: id, value: value, type: "line" };
        this.#curRelative.push(line);
        return this;
    }

    AddHR(id, thickness) {
        this.#CheckParam(id, "id", "AddHR");
        this.#CheckParam(thickness, "thickness", "AddHR");

        let hr = { id: id, thickness: thickness, type: "hr" };
        this.#curRelative.push(hr);
        return this;
    }

    StartSplitLine(id) {
        this.#CheckParam(id, "id", "StartSplitLine");

        let splitLine = { id: id, items: [], type: "split-line" };
        this.#curRelative.push(splitLine);
        this.#NewRel(splitLine.items);
        this.#curId.push(id);
        return this;
    }

    EndSplitLine(id) {
        if (id !== undefined) {
            let foundId = this.#curId.pop();
            if (id !== foundId) {
                throw new ReportRequestException(`EndSplitLine expected to be ending ID ${id}, but instead was ending id ${foundId}`);
            }
        }

        this.#EndRel();
        return this;
    }

    Build(templateId) {
        this.#CheckParam(templateId, "templateId", "Build");

        return { templateId: templateId, relative: this.#relStack[0] };
    }

    StartRepeat(id) {
        this.#CheckParam(id, "id", "StartRepeat");
        this.#curId.push(id);

        let repeat = { id: id, items: [], type: "repeat" }
        this.#curRelative.push(repeat);
        this.#NewRel(repeat.items);

        return this;
    }

    StartRepeatItem(id) {
        if (id !== undefined) {
            this.#curId.push(id)
        }

        let itemArr = [];
        this.#curRelative.push(itemArr);
        this.#NewRel(itemArr);
        return this;
    }

    EndRepeatItem(id) {
        if (id !== undefined) {
            let foundId = this.#curId.pop();
            if (id !== foundId) {
                throw new ReportRequestException(`EndRepeatItem expected to be ending ID ${id}, but instead was ending id ${foundId}`);
            }
        }

        this.#EndRel();
        return this;
    }

    EndRepeat(id) {
        if (id !== undefined) {
            let foundId = this.#curId.pop();
            if (id !== foundId) {
                throw new ReportRequestException(`EndRepeat expected to be ending ID ${id}, but instead was ending id ${foundId}`);
            }
        }

        this.#EndRel();
        return this;
    }

}