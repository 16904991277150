/* eslint no-dupe-class-members: 0 */

import ReportRequest from "../report/ReportRequest";
import DocxBuilder from "../docxgen/DocxBuilder";
import ReportBuilderBase from "./ReportBuilderBase";
import * as disp from "../utility/display";

export default class ReportBuilderStudent extends ReportBuilderBase {

    ReportName = "studentResume.2";
    #request;
    #document;

    constructor() {
        super();
    }

    CreateText(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points) {
        super.CheckParam(nameDisplay, "nameDisplay", "BuildReport");
        super.CheckParam(addressDisplay, "addressDisplay", "BuildReport");
        super.CheckParam(adjectiveDisplay, "adjectiveDisplay", "BuildReport");
        super.CheckParam(hardSkills, "hardSkills", "BuildReport");
        super.CheckParam(hardSkillsLabels, "hardSkillsLabels", "BuildReport");
        super.CheckParam(softSkills, "softSkills", "BuildReport");
        super.CheckParam(roles, "roles", "BuildReport");
        super.CheckParam(careerInterestDisplay, "careerInterestDisplay", "BuildReport");
        super.CheckParam(educationList, "educationList", "BuildReport");
        super.CheckParam(workList, "workList", "BuildReport");
        super.CheckParam(workDict, "workDict", "BuildReport");
        super.CheckParam(languages, "languages", "BuildReport");
        super.CheckParam(licenses, "licenses", "BuildReport");
        super.CheckParam(profOrg, "profOrg", "BuildReport");
        super.CheckParam(volunteerOrg, "volunteerOrg", "BuildReport");
        super.CheckParam(interest, "interest", "BuildReport");
        super.CheckParam(otherSkills, "otherSkills", "BuildReport");
        super.CheckParam(points, "points", "BuildReport");

        // set namedisplay, address display
        this.nameDisplay = nameDisplay;
        this.addressDisplay = addressDisplay;

        // Build professional summary
        let roleDisplay = super.BuildStringList(roles.map(r => r.text));
        let hardSkillsTopDisplay = super.BuildStringList(hardSkills.filter(h => h.level == undefined || h.level == 1).map(h => h.text));
        let softSkillsDisplay = super.BuildStringList(softSkills.map(s => s.text));
        this.profSum = super.BuildProfessionalSummary(adjectiveDisplay, careerInterestDisplay, roleDisplay, hardSkillsTopDisplay, softSkillsDisplay, workDict);

        // Build skills
        [this.skillHeaders, this.skillValues] = super.BuildSkillArray(hardSkills, hardSkillsLabels, otherSkills);

        // Build additional items array
        [this.addArrLabels, this.addArrValues] = this.BuildAdditionalItems(softSkills, roles, languages, licenses, profOrg, volunteerOrg, interest, points);
    }

    BuildDocx(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points) {
        // init all text objects
        this.CreateText(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points);

        // setup document
        this.#document = new DocxBuilder();

        // add name and address
        this.#document
            .AddTitle(this.nameDisplay)
            .AddParagraph(this.addressDisplay);

        // Add education
        // Education
        if (educationList.length > 0) {
            this.#document.AddHeading1("EDUCATION", true);

            educationList.forEach(edu => {
                let eduLines = [edu.expectedGrad, edu.degreeMinor, edu.gpa, ...edu.accolades].filter(i => !disp.IsNullorWhitespace(i));
                this.#document
                    .AddSplitHeading(edu.general, edu.location)
                    .AddSplitSubHeading(edu.degree, edu.date)
                    .AddBulletList(eduLines);
            });
        }

        // Add Skills
        if (!(this.skillHeaders === null || this.skillHeaders.length == 0)) {
            let formattedHeaders = this.skillHeaders.map(s => `${s}: `);
            this.#document
                .AddHeading1("SKILLS AND ABILITIES", true)
                .AddFormattedBulletList(formattedHeaders, this.skillValues);
        }

        // Work
        if (workList.length > 0) {
            this.#document.AddHeading1("EXPERIENCE", true);
            workList.forEach(work => {
                let firstWorkRole = work.roles.length > 0 ? work.roles[0] : "";

                this.#document
                    .AddSplitHeading(work.general, work.location)
                    .AddSplitSubHeading(firstWorkRole, work.date);

                if (work.roles.length > 1) {
                    for (let i = 1; i < work.roles.length; i++) {
                        this.#document.AddSplitSubHeading(work.roles[i], "");
                    }
                }

                this.#document.AddBulletList([work.responsibilities, ...work.achievements]);
            });
        }

        // Add additional items
        if (this.addArrLabels.length > 0) {
            let formattedLabels = this.addArrLabels.map(l => `${l}: `);
            this.#document
                .AddHeading1("ADDITIONAL INFORMATION", true)
                .AddFormattedBulletList(formattedLabels, this.addArrValues);
        }

        // return blob
        return this.#document.BuildSection().GetBlob();
    }

    BuildReport(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points) {

        // init all text objects
        this.CreateText(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points);

        this.#request = new ReportRequest();
        this.#request
            .AddLine("name", nameDisplay)
            .AddLine("address", addressDisplay)

        // Education
        if (educationList.length > 0) {
            this.#request.StartRepeat("repeatEdu");
            educationList.forEach(edu => {
                this.#request
                    .StartRepeatItem()
                    .StartSplitLine("splitedu1")
                    .AddLine("eduSchool", edu.general)
                    .AddLine("eduLocation", edu.location)
                    .EndSplitLine("splitedu1")
                    .StartSplitLine("splitedu2")
                    .AddLine("eduDegree", edu.degree)
                    .AddLine("eduTimeFrame", edu.date)
                    .EndSplitLine("splitedu2")
                    .StartRepeat("repeateduitems");

                [edu.expectedGrad, edu.degreeMinor, edu.gpa, ...edu.accolades].forEach((acc) => {
                    this.#request
                        .StartRepeatItem()
                        .AddLine("eduitem", acc)
                        .EndRepeatItem()
                });

                this.#request
                    .EndRepeat("repeateduitems")
                    .EndRepeatItem()
            });
            this.#request.EndRepeat("repeatEdu");
        }
        else {
            this.#request.AddLine("educationTitle", "")
                .AddHR("eduhr", 0);
        }

        // setup skills
        let skillArr = super.ReportFormatTwoLists(this.skillHeaders, this.skillValues);

        if (skillArr === null || skillArr.length == 0) {
            this.#request.AddLine("skillsTitle", "")
                .AddHR("skillshr", 0);
        } else {
            this.#request.StartRepeat("repeatSkills");
            skillArr.forEach(skill => {
                this.#request
                    .StartRepeatItem()
                    .AddLine("skillLine", skill)
                    .EndRepeatItem();
            })
            this.#request.EndRepeat("repeatSkills");
        }

        // Work
        if (workList.length > 0) {
            this.#request.StartRepeat("repeatWork");
            workList.forEach(work => {
                let firstWorkRole = work.roles.length > 0 ? work.roles[0] : "";

                this.#request
                    .StartRepeatItem()
                    .StartSplitLine("splitwork1")
                    .AddLine("workCompany", work.general)
                    .AddLine("workLocation", work.location)
                    .EndSplitLine("splitwork1")
                    .StartSplitLine("splitwork2")
                    .AddLine("workTitle", firstWorkRole)
                    .AddLine("workTimeFrame", work.date)
                    .EndSplitLine("splitwork2");

                if (work.roles.length > 1) {
                    this.#request.StartRepeat("repeatWorkTitle");
                    for (let i = 1; i < work.roles.length; i++) {
                        this.#request.StartRepeatItem()
                            .AddLine("workTitleExtra", work.roles[i])
                            .EndRepeatItem();
                    }
                    this.#request.EndRepeat("repeatWorkTitle");
                }

                this.#request.StartRepeat("repeatworkachieve");
                [work.responsibilities, ...work.achievements].forEach(ach => {
                    this.#request
                        .StartRepeatItem()
                        .AddLine("workachieve", ach)
                        .EndRepeatItem()
                });
                this.#request
                    .EndRepeat("repeatworkachieve")
                    .EndRepeatItem();
            });
            this.#request.EndRepeat("repeatWork");
        } else {
            this.#request.AddLine("workTitle", "")
                .AddHR("workHR", 0);
        }

        // Write additional items array
        if (this.addArrLabels.length > 0) {
            // build formatted list
            let arr = super.ReportFormatTwoLists(this.addArrLabels, this.addArrValues);
            this.#request.StartRepeat("repeatAdditional");
            arr.forEach(add => {
                this.#request
                    .StartRepeatItem()
                    .AddLine("addLine", add)
                    .EndRepeatItem();
            })
            this.#request.EndRepeat("repeatAdditional");
        } else {
            this.#request.AddLine("additionalTitle", "")
                .AddHR("addhr", 0);
        }

        // Return build report object
        return this.#request.Build(this.ReportName);
    }

    BuildAdditionalItems(softSkills, roles, languages, licenses, profOrg, volunteerOrg, interest, points) {
        let addArrLabels = [];
        let addArrValues = [];

        let ss = softSkills.map(s => s.text);
        let rs = roles.map(r => r.text);

        let cSkills = [...ss];
        if (cSkills.length > 0) {
            let skills = super.BuildStringList(cSkills);
            addArrLabels.push("Leadership Skills");
            addArrValues.push(`${skills}.`);
        }

        if (rs.length > 0) {
            let roleText = super.BuildStringList(rs);
            addArrLabels.push("Roles");
            addArrValues.push(`Experience acting as ${disp.GetLeadingArticle(roleText)} ${disp.CapitalizeWords(roleText)}.`);
        }

        if (!disp.IsNullorWhitespace(languages)) {
            addArrLabels.push("Languages");
            addArrValues.push(`${languages}.`);
        }

        if (!disp.IsNullorWhitespace(licenses)) {
            addArrLabels.push("Professional Licenses and Certifications");
            addArrValues.push(`${licenses}.`);
        }

        if (!disp.IsNullorWhitespace(profOrg)) {
            addArrLabels.push("Professional Organizations");
            addArrValues.push(`${profOrg}.`);
        }

        if (!disp.IsNullorWhitespace(volunteerOrg)) {
            addArrLabels.push("Volunteer Organizations");
            addArrValues.push(`${volunteerOrg}.`);
        }

        if (!disp.IsNullorWhitespace(interest)) {
            addArrLabels.push("Interests");
            addArrValues.push(`${interest}.`);
        }

        if (points?.length > 0) {
            addArrLabels.push("Other");
            addArrValues.push(`${disp.BuildCommaString("", points)}.`);
        }

        return [addArrLabels, addArrValues];
    }
}