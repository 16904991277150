<template>
  <v-dialog transition="dialog-top-transition" max-width="800" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" block v-bind="attrs" v-on="on">
        Send to Career Center
      </v-btn>
    </template>
    <v-card>
      <v-system-bar
        :height="$vuetify.breakpoint.xs ? 75 : 50"
        color="primary"
        class="white--text elevation-5"
      >
        <span class="text-h6 pl-2">Submit Your Resume For Review</span>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <div class="pt-4 pb-3 px-6">
        <v-form ref="dialogForm" @submit.prevent="submit()">
          <p class="text-body-1">
            Your Resume will be sent to the {{displayName}} Career Center.
            They will review and provide specific feedback to help you improve
            your Resume.
          </p>
          <p>
            You can find all of your submitted Resumes and feedback on the
            <a
              @click="nav('/SubmittedResumes')"
              class="text-decoration-underline"
              >Your Resumes</a
            >
            page.
          </p>
          <v-text-field
            label="Give a name to this version of your Resume"
            v-model="resumeName"
            :rules="nameRules"
          ></v-text-field>
          <v-textarea
            label="Notes for the Career Center (Optional)"
            v-model="resumeNote"
          ></v-textarea>
          <v-text-field
            disabled
            label="Date Submitted"
            v-model="curDate"
          ></v-text-field>
          <div class="d-flex justify-space-between">
            <v-btn type="submit" color="accent">Submit for Review</v-btn>
            <v-btn @click="dialog = false" outlined>Cancel</v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import * as disp from "../utility/display"
import { required } from "../utility/validation.js";
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "SubmitForReview",
  data: () => ({
    dialog: false,
    curDate: "",
    resumeName: "",
    resumeNote: "",
    nameRules: [(value) => required(value)],
  }),
  props: {
    title: {
      type: String,
      default: "Send to Career Center",
    },
  },
  mounted() {
    let d = new Date();
    this.curDate = d.toLocaleDateString("en-us");
  },
  computed: {
    ...mapFields("envStyle", ["displayName"])
  },
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    submit() {
      if (!this.$refs.dialogForm.validate()) {
        return;
      }
      this.$emit("submit", { name: this.resumeName, note: this.resumeNote });
      this.dialog = false;
    },
  },
};
</script>