/* eslint no-dupe-class-members: 0 */

import axios from "axios";
import * as log from "../utility/logging/logger.js";

export default class DataAccess {
  constructor(baseUrl) {
    if ((baseUrl?.length ?? null) === null) {
      this.baseUrl = null;
      return;
    }

    if (baseUrl[baseUrl.length - 1] != "/") {
      baseUrl += "/";
    }
    this.baseUrl = baseUrl;
  }

  async GetToken(clientId, clientSecret, scope = null) {
    if (scope === null) {
      scope = "read_template write_template build_report";
    }

    let requestBody = {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: "client_credentials",
      scope: scope
    };

    var result = await this.#Post(`${this.baseUrl}token`, requestBody);

    if (result?.success ?? false) {
      return result.data;
    }

    return result;
  }

  async GetTemplate(templateId, token) {
    var result = await this.#Get(
      `${this.baseUrl}template/${templateId}`,
      token
    );
    if (result?.success ?? false) {
      this.template = result.data;
      return result.data;
    }

    return null;
  }

  HydrateTemplate(template, buildRequest) {
    template.relative = this.#HydrateRelativeValues(
      template.relative,
      buildRequest.relative
    );
    return template;
  }

  #DeepCopyTemplateItem(item) {
    let itemCopy = {
      id: item.id,
      type: item.type,
      x: item.x,
      y: item.y,
      width: item.width
    };
    if (item.type === "line") {
      itemCopy.value = item.value;
      itemCopy.size = item.size;
      itemCopy.align = item.align;
      itemCopy.valign = item.valign;
    } else if (item.type === "split-line") {
      itemCopy.items = item.items.map(i => this.#DeepCopyTemplateItem(i));
    } else if (item.type == "repeat") {
      itemCopy.items = item.items.map(i => ({
        relative: i.relative.map(r => this.#DeepCopyTemplateItem(r))
      }));
    }

    return itemCopy;
  }

  #HydrateRelativeValues(relative, requestValues) {
    let output = [];

    let lineValueSet = {};
    let splitLineValueSet = {};
    let repeatValueSet = {};

    if (requestValues === undefined || requestValues === null) {
      requestValues = [];
    }

    if (relative === undefined || relative === null) {
      return output;
    }

    requestValues.forEach(value => {
      if (value.id !== undefined && value.id !== null) {
        if (value.type === "line") {
          lineValueSet[value.id] = value.value;
        } else if (value.type === "split-line") {
          splitLineValueSet[value.id] = value.items;
        } else if (value.type === "repeat") {
          repeatValueSet[value.id] = value.items;
        }
      }
    });

    relative.forEach(item => {
      if (item.type === "line") {
        let newItem = this.#DeepCopyTemplateItem(item);
        if (lineValueSet[item.id] !== undefined) {
          newItem.value = lineValueSet[item.id];
        }
        output.push(newItem);
      } else if (item.type === "split-line") {
        if (splitLineValueSet[item.id] !== undefined) {
          let newSplit = this.#DeepCopyTemplateItem(item);
          newSplit.items = this.#HydrateRelativeValues(
            newSplit.items,
            splitLineValueSet[item.id]
          );
          output.push(newSplit);
        }
      } else if (item.type === "repeat") {
        if (repeatValueSet[item.id] !== undefined) {
          let repeatItem = this.#DeepCopyTemplateItem(item);
          let templateRelative = repeatItem.items[0]; // should only be one item in the template

          let itemList = [];
          repeatValueSet[item.id].forEach(repeatValues => {
            let newTemplateRelative = {};
            newTemplateRelative.relative = this.#HydrateRelativeValues(
              templateRelative.relative,
              repeatValues
            );
            itemList.push(newTemplateRelative);
          });

          repeatItem.items = itemList;
          output.push(repeatItem);
        }
      }
    });

    return output;
  }

  async GetReportPDF(buildRequest, token) {
    return this.#GetReport(buildRequest, token, "application/pdf", "pdf");
  }

  async GetReportPNG(buildRequest, token) {
    return this.#GetReport(buildRequest, token, "image/png", "png");
  }

  async #GetReport(buildRequest, token, mimeType, reportType) {
    buildRequest.reportType = reportType;
    
    var result = await this.#Post(
      `${this.baseUrl}Report`,
      buildRequest,
      token,
      "blob"
    );
    if (result?.success ?? false) {
      var outBlob = new Blob([result.data], { type: mimeType });
      return outBlob;
    }

    return null;
  }

  async #Post(apiUrl, postBody, token = null, responseType = "json") {
    log.logTrace(`Post: ${apiUrl}`, "DataAccess.Post");

    let config = {};
    if ((token?.length ?? null) !== null) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: responseType
      };
    }

    var result = await axios
      .post(apiUrl, postBody, config)
      .then(result => {
        return { data: result.data, success: true };
      })
      .catch(err => {
        log.logWarning(`Error: ${err?.toJSON()?.message}`, "DataAccess.Post");
        return { success: false, msg: "Unexpected Error", ...err };
      });

    return result;
  }

  async #Get(apiUrl, token = null, responseType = "json") {
    log.logTrace(`Get: ${apiUrl}`, "DataAccess.Get");

    let config = {};
    if ((token?.length ?? null) !== null) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: responseType
      };
    }

    var result = await axios
      .get(apiUrl, config)
      .then(result => {
        return { data: result.data, success: true };
      })
      .catch(err => {
        log.logWarning(`Error: ${err?.toJSON()?.message}`, "DataAccess.Get");
        return { success: false, msg: "Unexpected Error", ...err };
      });

    return result;
  }
}
